import React from "react"

const Navigation = () => (
    <div className="navigation">
        <div className="container">
            <input className="menu-btn" type="checkbox" id="menu-btn" name="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn">
                <span className="nav-icon"></span>
            </label>
            <ul className="menu">
                <li>
                    <a href="/">Start</a>
                </li>
                <li>
                    <a href="/oferta">Oferta</a>
                </li>
                <li>
                    <a href="/galeria">Galeria</a>
                </li>
                <li>
                    <a href="/aktualnosci">Aktualności</a>
                </li>
                <li>
                    <a href="/porady">Porady</a>
                </li>
                <li>
                    <a href="/kontakt">Kontakt</a>
                </li>
            </ul>
        </div>
    </div>
)

export default Navigation
