import React from "react"
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import form_zus from "../../downloads/form_zus.pdf"
import form_krus from "../../downloads/form_krus.pdf"
import rodo from "../../downloads/rodo.pdf"
import upopl from "../../downloads/upowaznienie_pl.pdf"
import upoint from "../../downloads/upowaznienie_int.pdf"

const Footer = ({ image }) => (
    <footer className="footer">
        <div className="footer__top">
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <Img className="footer__logo" fluid={image.logo.childImageSharp.fluid} alt="ABRAM" />
                        <ul>
                            <li>obsługa i organizacja pogrzebów</li>
                            <li>sprzedaż trumien i urn</li>
                            <li>kremacje oraz ekshumacje</li>
                            <li>transport zmarłych</li>
                            <li>kosmetyka pośmiertna</li>
                            <li>kopanie grobów</li>
                            <li>rozbiórka i montaż nagrobków</li>
                            <li>odzież dla zmarłych</li>
                        </ul>
                    </div>
                    <div className="column">
                        <h3>Formularze</h3>
                        <ul className="forms">
                            <li><a href={form_zus} target="_blank" rel="noopener noreferrer">Wniosek o zasiłek ZUS</a></li>
                            <li><a href={form_krus} target="_blank" rel="noopener noreferrer">Wniosek o zasiłek KRUS</a></li>
                            <li><a href={rodo} target="_blank" rel="noopener noreferrer">Formularz informacyjny RODO</a></li>
                            <li><a href={upopl} target="_blank" rel="noopener noreferrer">Upoważnienie Polska</a></li>
                            <li><a href={upoint} target="_blank" rel="noopener noreferrer">Upoważnienie Międzynarodowe</a></li>
                        </ul>
                    </div>
                    <div className="column is-half">
                        <h3>Kontakt</h3>
                        <div className="columns">
                            <div className="column">
                                <div className="info">
                                    <div className="info__icon">
                                        <FontAwesomeIcon icon={'map-marker-alt'} />
                                    </div>
                                    <div className="info__data">
                                        Limanowskiego 31<br />
                                        63-400 Ostrów Wielkopolski
                                    </div>
                                </div>
                                <div className="info">
                                    <div className="info__icon">
                                        <FontAwesomeIcon icon={'map-marker-alt'} />
                                    </div>
                                    <div className="info__data">
                                        Okólna 29<br />
                                        63-400 Ostrów Wielkopolski
                                    </div>
                                </div>
                                <a className="info info__single" href="mailto:biuro@abram.net.pl" target="_blank" rel="noopener noreferrer">
                                    <div className="info__icon">
                                        <FontAwesomeIcon icon={'at'} />
                                    </div>
                                    <div className="info__data">
                                        biuro@abram.net.pl
                                    </div>
                                </a>
                                <a className="info info__single" href="tel:+48627371040" target="_blank" rel="noopener noreferrer">
                                    <div className="info__icon">
                                        <FontAwesomeIcon icon={'phone-alt'} />
                                    </div>
                                    <div className="info__data">
                                        +48 62 737-10-40
                                    </div>
                                </a>
                            </div>
                            <div className="column">
                                <a className="info info__single" href="tel:+48797126860" target="_blank" rel="noopener noreferrer">
                                    <div className="info__icon">
                                        <FontAwesomeIcon icon={'mobile'} />
                                    </div>
                                    <div className="info__data">
                                        +48 797 126 860
                                    </div>
                                </a>
                                <a className="info info__single" href="https://wa.me/48661235125" target="_blank" rel="noopener noreferrer">
                                    <div className="info__icon">
                                        <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                                    </div>
                                    <div className="info__data">
                                        +48 661 235 125
                                    </div>
                                </a>
                                <a className="info info__single" href="https://fb.me/abram.uslugi.pogrzebowe" target="_blank" rel="noopener noreferrer">
                                    <div className="info__icon">
                                        <FontAwesomeIcon icon={['fab', 'facebook']} />
                                    </div>
                                    <div className="info__data">
                                        fb.me/abram.uslugi.pogrzebowe
                                    </div>
                                </a>
                                <a className="info info__single" href="https://www.instagram.com/abram_uslugi_pogrzebowe/" target="_blank" rel="noopener noreferrer">
                                    <div className="info__icon">
                                        <FontAwesomeIcon icon={['fab', 'instagram']} />
                                    </div>
                                    <div className="info__data">
                                        @abram_uslugi_pogrzebowe
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer__bottom">
            <div className="container text-center">
                &copy; 2021 <strong>Usługi Pogrzebowe ABRAM</strong> - wszelkie prawa zastrzeżone. Projekt i wykonanie <strong>Holewa Software Solutions</strong>.
            </div>
        </div>
    </footer>
)

export default Footer
